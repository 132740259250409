import { graphql } from "gatsby";
import React, { useEffect } from "react";
import { css } from "@emotion/react";
import { Text, Title } from "@components/Typography";
import SEO from "@components/seo";
import SliderHero from "@components/SliderHero";
import { Section, Div, Container } from "@components/Markup";
import KarriereThumbnail from "@components/KarriereThumbnail";
import gsap from "gsap";

// const clamp = (val, min, max) => Math.min(Math.max(val, min), max);

const KarrierePage = ({ data }) => {
  const seoTitle = `Hermann & Valentiny und Partner Architekten | Karriere`;
  const seoDescription = `Hermann & Valentiny und Partner Architekten | Karriere | Deine Zukunft, Unser Team`;

  const { datoCmsKarrierenSeiteHv, allDatoCmsKarriereHv } = data;
  const karrieren = allDatoCmsKarriereHv?.edges.map(edge => edge.node);
  const {
    einleitung,
    wasWirErwarten,
    wasDuErwartenKannst,
    featuredImages,
  } = datoCmsKarrierenSeiteHv;
  const seoImage = featuredImages ? featuredImages[0]?.fluid.src : null;

  useEffect(() => {
    const spans = document.querySelectorAll(".comment");
    gsap.set(spans, { display: "block" });
  }, []);

  return (
    <React.Fragment>
      <SEO title={seoTitle} description={seoDescription} image={seoImage} />
      <SliderHero
        items={featuredImages}
        pageTitle="Karriere"
        pageSlogan="Deine Zukunft, Unser Team"
      />
      <Section pt="sm" pb="sm">
        <Container>
          <Div mb="md">
            <Text
              className="comment"
              dangerouslySetInnerHTML={{ __html: einleitung }}
              css={css`
                max-width: 640px;
                display: none;
              `}
            />
          </Div>
          <Div mb="md">
            <Title size="sm" mb="sm">
              Was wir erwarten
            </Title>
            <Text
              className="comment"
              dangerouslySetInnerHTML={{ __html: wasWirErwarten }}
              css={css`
                max-width: 640px;
                display: none;
              `}
            />
          </Div>
          <Div mb="md">
            <Title size="sm" mb="sm">
              Was du erwarten kannst
            </Title>
            <Text
              className="comment"
              dangerouslySetInnerHTML={{ __html: wasDuErwartenKannst }}
              css={css`
                max-width: 640px;
                display: none;
              `}
            />
          </Div>
        </Container>
      </Section>
      <Section pt="md" pb="lg">
        <Container css={css``}>
          <Div
            flex
            css={css`
              flex-wrap: wrap;

              @media (min-width: 768px) {
                margin: 0px -16px;
              }
            `}
          >
            {karrieren.map((karriere, index) => {
              return (
                <KarriereThumbnail key={`karriere-${index}`} {...karriere} />
              );
            })}
          </Div>
        </Container>
      </Section>
    </React.Fragment>
  );
};

export default KarrierePage;

export const query = graphql`
  query KarriereQuery {
    datoCmsKarrierenSeiteHv {
      einleitung
      wasWirErwarten
      wasDuErwartenKannst
      featuredImages {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
    }
    allDatoCmsKarriereHv(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          slug
          postTitle
          excerpt
          postDate
          featuredImage {
            fluid {
              src
              srcSet
              aspectRatio
            }
          }
        }
      }
    }
  }
`;
